import React from 'react';
import { Placement } from 'react-bootstrap/Overlay';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BootstrapTooltip from 'react-bootstrap/Tooltip';

type Props = {
  id: string;
  title: string;
  placement?: Placement;
  children?: React.ReactNode;
};

export default function Tooltip({
  children,
  id,
  title,
  placement = 'top',
}: Props) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <BootstrapTooltip id={id}>
          <strong>{title}</strong>
        </BootstrapTooltip>
      }>
      {children}
    </OverlayTrigger>
  );
}
