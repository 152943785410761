import React, { MouseEvent } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { IoMdPower } from 'react-icons/io';
import { useAuth } from '../../auth';
import { getHostUrl } from '../../utils';
import Tooltip from '../utils/tooltip';
import './profile-dropdown.scss';

export type ProfileDropdownProps = {
  children: React.ReactNode;
};

export default function ProfileDropdown({ children }: ProfileDropdownProps) {
  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={Dropdown}>
      <div>{children}</div>
    </OverlayTrigger>
  );
}

function Dropdown(props: any) {
  const { user, logout } = useAuth();

  function handleLogoutClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    logout({ returnTo: getHostUrl() });
  }

  return (
    <Popover id="user-dropdown" {...props}>
      <Popover.Title as="h3" className="text-center">
        {user.name}
      </Popover.Title>
      <Popover.Content>
        <div className="d-flex justify-content-around">
          <Tooltip id="logout-user" title="Logout">
            <Button
              variant="secondary"
              className="m-2 dropdown-action-button"
              onClick={handleLogoutClick}>
              <IoMdPower />
            </Button>
          </Tooltip>
        </div>
      </Popover.Content>
    </Popover>
  );
}
