import React, { ChangeEvent, FormEvent, useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { MdInput } from 'react-icons/md';
import './lobby.scss';
import LoadingButton from '../../utils/loading-button';
import DisclaimerModal from '../disclaimer-modal';
import { useAuth } from '../../../auth';

export type LobbyProps = {
  appointmentCode: string;
  handleAppointmentCodeChange: (value: string) => void;
  handleSubmit: () => void;
};

export default function Lobby({
  appointmentCode,
  handleAppointmentCodeChange,
  handleSubmit,
}: LobbyProps) {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  function handleCancelDisclaimer() {
    setShowDisclaimer(false);
    setLoading(false);
  }

  function handleConfirmDisclaimer() {
    setShowDisclaimer(false);
    setLoading(false);
    handleSubmit();
  }

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    if (isAuthenticated) {
      handleConfirmDisclaimer();
    } else {
      setShowDisclaimer(true);
    }
  }

  function onAppointmentCodeChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    handleAppointmentCodeChange(event.target.value);
  }

  const inputClassName = `mb-3 appointment-code-input ${
    loading ? 'disabled' : ''
  }`;

  return (
    <div className="lobby">
      <DisclaimerModal
        show={showDisclaimer}
        onCancel={handleCancelDisclaimer}
        onConfirm={handleConfirmDisclaimer}
      />
      <form onSubmit={onSubmit}>
        <h2 className="code-prompt">Enter Your Appointment ID</h2>
        <div>
          <InputGroup className={inputClassName}>
            <FormControl
              autoFocus
              required
              disabled={loading}
              aria-describedby="appointment-code"
              value={appointmentCode}
              onChange={onAppointmentCodeChange}
            />
            <InputGroup.Append>
              <LoadingButton
                type="submit"
                tooltip="Submit"
                className="submit-button"
                tooltipPlacement="top"
                loading={loading}>
                <MdInput fontSize="2em" />
              </LoadingButton>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </form>
    </div>
  );
}
