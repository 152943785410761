import Image from 'react-bootstrap/Image';
import React from 'react';
import { useAuth } from '../../auth';
import './profile-picture.scss';

export type ProfilePictureProps = {
  className?: string;
};

export default function ProfilePicture({ className }: ProfilePictureProps) {
  const { loading, user } = useAuth();

  if (loading || !user) {
    return <div>Loading...</div>;
  }

  return (
    <Image
      className={`${className} profile-picture`}
      roundedCircle
      src={user.picture}
      alt={user.name}
    />
  );
}
