export function debugEnabled() {
  const envVal = process.env.REACT_APP_DEBUG;
  if (!envVal) {
    return false;
  }
  const numberVal = Number(envVal);
  return !Number.isNaN(numberVal) && numberVal > 0;
}

export function debugLog(...args: any[]) {
  if (debugEnabled()) {
    console.log(...args);
  }
}
