import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Lobby from './lobby';

export default function VideoChat() {
  const [appointmentCode, setAppointmentCode] = useState('');
  const [gotoRoom, setGotoRoom] = useState(false);

  const handleAppointmentCodeChange = useCallback((value: string) => {
    setAppointmentCode(value);
  }, []);

  const handleSubmit = useCallback(() => {
    setGotoRoom(true);
  }, [appointmentCode]);

  if (gotoRoom) {
    const roomUrl = `/room/${appointmentCode}`;
    return <Redirect to={roomUrl} />;
  }

  return (
    <Lobby
      appointmentCode={appointmentCode}
      handleAppointmentCodeChange={handleAppointmentCodeChange}
      handleSubmit={handleSubmit}
    />
  );
}
