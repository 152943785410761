import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  NetworkQualityLevel,
  NetworkQualityStats,
  RemoteParticipant as RemoteParticipantType,
  RemoteTrackPublication,
} from 'twilio-video';
import { debugLog } from '../../../utils/debug';
import './remote-participant.scss';
import { AudioTrackType, TrackType, VideoTrackType } from './types';
import {
  getParticipantAudioTracks,
  getParticipantVideoTracks,
  hasAudioTrack,
  hasVideoTrack,
  isAudioTrack,
  isVideoTrack,
} from './utils';

export type RemoteParticipantProps = {
  participant: RemoteParticipantType;
};

export default function RemoteParticipant({
  participant,
}: RemoteParticipantProps) {
  const [videoTracks, setVideoTracks] = useState<VideoTrackType[]>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrackType[]>([]);

  const videoRef = useRef() as RefObject<HTMLVideoElement>;
  const audioRef = useRef() as RefObject<HTMLAudioElement>;

  useEffect(() => {
    /* Track Subscriptions */

    /* remote user started a new stream */

    function trackPublished(trackPublication: RemoteTrackPublication) {
      if (hasVideoTrack(trackPublication)) {
        debugLog('remote video track published');
      } else if (hasAudioTrack(trackPublication)) {
        debugLog('remote audio track published');
      }
    }

    function trackSubscribed(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('remote video track subscribed');
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else if (isAudioTrack(track)) {
        debugLog('remote audio track subscribed');
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    }

    /* remote user finished an active stream */
    function trackUnsubscribed(track: TrackType) {
      if (isVideoTrack(track)) {
        debugLog('remote video track unsubscribed');
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (isAudioTrack(track)) {
        debugLog('remote audio track unsubscribed');
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    }

    /* Track Enabling and Disabling */

    /* remote user "unmuted" data in a stream */
    function trackEnabled(trackPublication: RemoteTrackPublication) {
      if (hasVideoTrack(trackPublication)) {
        debugLog('remote video track enabled');
      } else if (hasAudioTrack(trackPublication)) {
        debugLog('remote audio track enabled');
      }
    }

    /* remote user "muted" data in a stream */
    function trackDisabled(trackPublication: RemoteTrackPublication) {
      if (hasVideoTrack(trackPublication)) {
        debugLog('remote video track disabled');
      } else if (hasAudioTrack(trackPublication)) {
        debugLog('remote audio track disabled');
      }
    }

    /* Network Connection Events */

    function handleReconnecting() {
      debugLog('remote participant is reconnecting');
    }

    function handleReconnected() {
      debugLog('remote participant is reconnected');
    }

    function handleNetworkQualityLevelChanged(
      qualityLevel: NetworkQualityLevel,
      qualityStats: NetworkQualityStats,
    ) {
      debugLog(
        'remote participant network quality level changed, level:',
        qualityLevel,
      );
    }

    setAudioTracks(getParticipantAudioTracks(participant));
    setVideoTracks(getParticipantVideoTracks(participant));

    participant.on('trackPublished', trackPublished);
    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);
    participant.on('trackEnabled', trackEnabled);
    participant.on('trackDisabled', trackDisabled);
    participant.on('reconnecting', handleReconnecting);
    participant.on('reconnected', handleReconnected);
    participant.on(
      'networkQualityLevelChanged',
      handleNetworkQualityLevelChanged,
    );
  }, [participant]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack && audioRef.current) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  return (
    <div className="participant">
      <video ref={videoRef} autoPlay={true} />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    </div>
  );
}
