export function getApiUrl() {
  const envVal = process.env.REACT_APP_API_URL || '';
  const stringVal = String(envVal);
  if (stringVal) {
    return stringVal;
  }

  return (
    'https://' +
    window.location.hostname
      .split('.')
      .map(c => (c === 'fe' ? 'be' : c))
      .join('.')
  );
}

export function getHostUrl() {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) {
    return `http://${hostname}:3000`;
  }
  return `https://${hostname}`;
}
