import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaUserMd } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { useToasts } from 'react-toast-notifications';
import Tooltip from '../../utils/tooltip';
import './waiting-screen.scss';

export type WaitingScreenProps = {
  appointmentCode: string;
  message: string;
};

export default function WaitingScreen({
  appointmentCode,
  message,
}: WaitingScreenProps) {
  const { addToast } = useToasts();

  function showCopiedNotification() {
    addToast('Copied to Clipboard', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  return (
    <div className="waiting-message-container">
      <FaUserMd className="waiting-message-icon" />
      <div className="waiting-message-description">{message}</div>
      <div className="waiting-message-code">
        <CopyToClipboard
          onCopy={showCopiedNotification}
          text={appointmentCode.trim()}>
          <div className="d-inline-block mr-3">{appointmentCode.trim()}</div>
        </CopyToClipboard>

        <Tooltip id="copy" title="Copy">
          <CopyToClipboard
            onCopy={showCopiedNotification}
            text={appointmentCode.trim()}>
            <MdContentCopy />
          </CopyToClipboard>
        </Tooltip>
      </div>
    </div>
  );
}
