import React, { CSSProperties, MouseEvent } from 'react';
import Button, {
  ButtonProps as BootstrapButtonProps,
} from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Tooltip from './tooltip';
import { Placement } from 'react-bootstrap/Overlay';

type Props = BootstrapButtonProps & {
  loading: boolean;
  tooltip?: string;
  tooltipPlacement?: Placement;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

export default function LoadingButton({
  children,
  loading,
  tooltip,
  tooltipPlacement,
  ...buttonProps
}: Props) {
  const button = (
    <Button {...buttonProps} disabled={loading}>
      {loading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );

  if (tooltip) {
    return (
      <Tooltip id={tooltip} title={tooltip} placement={tooltipPlacement}>
        {button}
      </Tooltip>
    );
  }

  return button;
}
