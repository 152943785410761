import {
  AudioTrackPublication,
  Participant,
  RemoteParticipant,
  RemoteTrackPublication,
  VideoTrackPublication,
} from 'twilio-video';
import { AudioTrackType, TrackType, VideoTrackType } from './types';

export function getParticipantTracks(
  participant: RemoteParticipant,
): TrackType[] {
  return Array.from(participant.tracks.values())
    .map(getTrack)
    .filter(isValidTrack);
}

export function getParticipantAudioTracks(
  participant: Participant,
): AudioTrackType[] {
  const publications = Array.from(participant.audioTracks.values());
  const tracks = publications.map(getTrack);
  return tracks.filter(isAudioTrack);
}

export function getParticipantVideoTracks(
  participant: Participant,
): VideoTrackType[] {
  const publications = Array.from(participant.videoTracks.values());
  const tracks = publications.map(getTrack);
  return tracks.filter(isVideoTrack);
}

export function hasAudioTrack(trackPublication: RemoteTrackPublication) {
  return (
    trackPublication.track !== null && trackPublication.track.kind === 'audio'
  );
}

export function hasVideoTrack(trackPublication: RemoteTrackPublication) {
  return (
    trackPublication.track !== null && trackPublication.track.kind === 'video'
  );
}

export function isAudioTrack(track: TrackType | null): track is AudioTrackType {
  return track !== null && track.kind === 'audio';
}

export function isVideoTrack(track: TrackType | null): track is VideoTrackType {
  return track !== null && track.kind === 'video';
}

function isValidTrack(track: TrackType | null): track is TrackType {
  return isAudioTrack(track) || isVideoTrack(track);
}

function getTrack(
  trackPublication:
    | RemoteTrackPublication
    | AudioTrackPublication
    | VideoTrackPublication,
) {
  return trackPublication.track as TrackType | null;
}
