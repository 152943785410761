export function isPractitioner(claims: Record<string, any>) {
  const roles = getRolesFromClaims(claims);
  return roles && roles.includes('practitioner');
}

export function getRolesFromClaims(
  claims: Record<string, any>,
): string[] | undefined {
  const claimKeys = Object.keys(claims);
  const rolesKey = claimKeys.find(key => key.includes('roles'));

  if (!rolesKey) {
    return undefined;
  }

  const roles = claims[rolesKey];
  if (!Array.isArray(roles) || roles.length < 1) {
    return undefined;
  }

  return roles;
}
